<template>
<div class="table-container" style="height: 500px; overflow: auto">
 <table class="table table is-striped table is-narrow table is-fullwidth">
  <thead>
    <tr>
      <th><abbr title="Rechnungs-Nummer">RG-Nr.</abbr></th>
      <th><abbr title="Rechnungs-Datum">Datum</abbr></th>
      <th><abbr title="Gesamtbetrag">Betrag</abbr></th>
      <th><abbr title="Status der Rechnung">Status</abbr></th>

    </tr>
  </thead>
<tbody>
    <tr v-for="item in invoices.items" :key="item.id">
      <th>{{ item.number }}</th>
      <td>{{ item.date }}</td>
      <td>{{ item.total }}</td>
      <td v-if="item.status ==='P'" bgcolor="lightgreen">Bezahlt</td>
      <td v-if="item.status ==='DR'" bgcolor="lightgrey">Entwurf</td>
      <td v-if="item.status ==='D'" bgcolor="black" style="color: red;">Geloescht</td>
      <td v-if="item.status ==='S'" bgcolor="lightblue">Gesendet</td>
    </tr>
    </tbody>
</table>
</div>
</template>
<script>
  export default {
    props: ['invoices'],
  };
</script>