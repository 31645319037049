<template>
<div>


<div class="tile is-ancestor">
  <div class="tile is-4 is-vertical is-parent">
    <div class="tile is-child box">
      <p class="text">Anzahl Rechnungen: {{invoices.items.length}}</p>
      <p class="mb-4 text">Anzahl Produkte / Dienstleistungen: {{users.length}}</p>
      <button class="mt-4 button" onclick="location.reload(true)">Aktualisieren...</button>
    </div>
    <div class="tile is-child box">
      <section class="hero is-primary is-small">
        <div class="hero-body is-small">
      <p class="title">Rechnungsübersicht</p>
        </div>
      </section>
      <div class="section table-container mt-4" style="height: 40vh; overflow: auto">
        
 <table class="table table is-striped table is-narrow table is-fullwidth">
  <thead>
    <tr>
      <th><abbr title="Rechnungs-Nummer">RG-Nr.</abbr></th>
      <th><abbr title="PDF Datei generieren">PDF</abbr></th>
      <th><abbr title="Rechnungs-Datum">Datum</abbr></th>
      <th><abbr title="Gesamtbetrag">Betrag</abbr></th>
      <th><abbr title="Status der Rechnung">Status</abbr></th>

    </tr>
  </thead>
<tbody>
    <tr v-for="item in invoices.items" :key="item.number" :class="{'is-selected has-background-grey-light has-text-black': (item.number == RGSelect)}">
      <th>{{ item.number }}</th>
      <th><span class="icon is-small" @click="downloadPDF(item.id)" v-bind:title="'Invoice_'+item.id+'.pdf'"><i class="far fa-file-pdf" aria-hidden="true"></i></span></th>
      <td>{{ item.date }}</td>
      <td>sFr. {{ item.total }}</td>
      <td v-if="item.status ==='P' && item.number != RGSelect" bgcolor="lightgreen">Bezahlt</td>
      <td v-if="item.status ==='DR' && item.number != RGSelect" bgcolor="lightgrey">Entwurf</td>
      <td v-if="item.status ==='D' && item.number != RGSelect" bgcolor="black" style="color: red;">Geloescht</td>
      <td v-if="item.status ==='S' && item.number != RGSelect" bgcolor="lightblue">Gesendet</td>
      <td v-if="item.status ==='P' && item.number == RGSelect" >Bezahlt</td>
      <td v-if="item.status ==='DR' && item.number == RGSelect">Entwurf</td>
      <td v-if="item.status ==='D' && item.number == RGSelect">Geloescht</td>
      <td v-if="item.status ==='S' && item.number == RGSelect">Gesendet</td>      
    </tr>
    </tbody>
</table>
</div>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="tabs is-boxed">
  <ul>
    <li v-bind:class="{ 'is-active' : tabselect === 'P'}">
      
      <a>
        <span class="icon is-small" @click="updateBinding('P')"><i class="fas fa-laptop" aria-hidden="true"></i></span>
        <span @click="updateBinding('P')">Produkte</span>
      </a>
    </li>
    <li v-bind:class="{ 'is-active' : tabselect === 'S'}">
      <a>
        <span class="icon is-small" @click="updateBinding('S')"><i class="fas fa-briefcase" aria-hidden="true"></i></span>
        <span @click="updateBinding('S')">Dienstleistungen</span>
      </a>
    </li>
    <li v-bind:class="{ 'is-active' : tabselect === ''}">
      <a>
        <span class="icon is-small" @click="updateBinding('')"><i class="fas fa-link" aria-hidden="true"></i></span>
        <span @click="updateBinding('')">Alles</span>
      </a>
    </li>
  </ul>
</div>

      
      <input class="input is-primary" type="text" placeholder="Suche..." v-model="filters.name.value"/>
    <input id="cat_input" class="form-control" v-model="filters.catalog_type.value" input type="hidden"/>
  <div style="height: 100%;">
      <v-table class="table table is-striped table is-narrow table is-fullwidth" 

        :data="users"
        :filters="filters"
        :currentPage.sync="currentPage"
        :pageSize="15"
        
        @totalPagesChanged="totalPages = $event"
      >
        <thead slot="head">
        <th>Datum</th>
        <th>Produkt</th>
        <th>Artikelnr.</th>
        <th>Anzahl</th>
        <th>Preis Stk.</th>
        

        </thead>
        <tbody slot="body" slot-scope="{displayData}">
        <tr v-for="row in displayData" :key="row.guid" @click="selectRow(row.numberID, row.ID_Count) " :class="{'is-selected has-background-grey-lighter has-text-black': (row.ID_Count == ArticleSelect)}">
          <td>{{ row.date }}</td>
          <td v-bind:title="row.description">{{ row.name }}</td>
          <td>{{ row.number }}</td>
          <td>{{ row.amount }}</td>
          <td>sFr. {{ row.price }}</td>
          

        </tr>
        </tbody>
        
      </v-table>
      </div>
      <div class="">
  <div class="navbar">
            <smart-pagination
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :directionLinks="directionLinks"
        :maxPageLinks="maxPageLinks"
      />
      </div>
  </div>    
    </div>
  </div>
</div>



      


</div>
</template>
<script>
import SI_API from "../api/Small_Invoice_API.js"; 
import download from 'downloadjs';
export default {
      props: ['invoices', 'token'],
      components: {

    },
 

  data() {
    return {
    tabselect: "P", 
    RGSelect: "", 
    ArticleSelect:"",
    
    users: [],
    filters: {
      name: { value: '', keys: ['name'] },
      catalog_type: { value: "P", keys: ['catalog_type'] }
    },
    currentPage: 1,
    totalPages: 0,
    directionLinks: true,
    maxPageLinks: 15
    };
  },
  name: "pl",

  async created() {
    let element=[]
    let products=[]
    let ID_Count=1000
    for (let index = 0; index < this.invoices.items.length; index++) {
      element = this.invoices.items[index];
      
      for (let index = 0; index < element.positions.length; index++) {
        products = element.positions[index];
        if (products.name =="" || element.status =="D") {}else{
          ID_Count++
          this.users.push({ID_Count:ID_Count, numberID: element.number, date: element.date, name: products.name, number:products.number, description:products.description, amount:products.amount, price: products.price, catalog_type: products.catalog_type})
        }
      }
    }
   
  
  },
    methods: {
    sortBy: function(sortKey) {
      this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
      this.sortKey = sortKey;
    },
    updateBinding(payload) {
      let el = document.getElementById("cat_input");
      el.value = payload;  
      this.tabselect=payload;  
      el.dispatchEvent(new Event('input'));
    },
    updateRGSelect(payload) {
      let el = document.getElementById("cat_input");
      el.value = payload;  
      this.tabselect=payload;  
      el.dispatchEvent(new Event('input'));
    },
    selectRow(payload, pl2){
    this.RGSelect = payload;
    this.ArticleSelect=pl2;
    },
    downloadPDF(payload){
      SI_API.GetPDFPath(this.token, payload).then(
        ((response) => {
           
           download(response, "Invoice_"+payload+".pdf", 'application/pdf')
         //  window.open(URL.createObjectURL(response.data));
        }))
    }

  }
};
</script>
<style SCSS>
.section {

  padding: 1rem;
  overflow-y: auto;
  direction: ltr;
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;




}

.section::-webkit-scrollbar {
  width: 20px;
}

.section::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 5px;
}

.section::-webkit-scrollbar-thumb {
  
  background-image: linear-gradient(180deg, #0bb655 0%, #04c299 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.smart-pagination{
  align-content:flex-start
}
.smart-pagination .page-link{
  border-color: #37cc8eb9;
  border-radius: 5px;
  border-width: thin;
  padding: 0.3em 0.6em;
  margin-right: 1em;
  text-decoration: none;
  border-style: solid;
  text-transform: uppercase;
}


</style>