
<template>
  <div class="home">
    <p class="mb-4">
    <section class="hero is-primary">
  <div class="hero-body">
    <p class="title">
      Small Invoice - MS Teams Dashboard
    </p>
    <p class="subtitle">
      {{invoices.items[0].contact_name}}
    </p>
  </div>
</section>
</p>


<ProductList :invoices="invoices" :token="token" />











  </div>
</template>
<script>
import ProductList from '../components/product_list';
import InvoiceList from '../components/invoice_list';
import SI_API from "../api/Small_Invoice_API.js";
export default {
      components: {
      ProductList,
      InvoiceList
    },
  data() {
    return {
      invoices: [],
      token:""
    };
  },
  name: "home",

  async created() {
    console.log(this.$route.query.id)
    SI_API.GetNewToken(this.$route.query.clientid).then((response) => {
      this.$set(this, "token", response);
      SI_API.GetDataInvoices(response, this.$route.query.id).then(
        ((response) => {
          this.$set(this, "invoices", response);
        }).bind(this)
      );
    });
  },
};
</script>
<style>
</style>