import axios from "axios"


const baseurl = 'https://api.smallinvoice.com/v2/'



export default {
  async GetNewToken(client_id) {
    let payload_token = {
      "grant_type": "client_credentials",
      "client_id": client_id,
      "client_secret": "hpHVKLXgPudGKwiPhIYs1jvO2XwRassIgWNatomOM9n2xLWTZW0dpMdfzgXhWoFMt7uV6Tm40D/PdI2w",
      "scope": "invoice contact"
    }
    let res = await axios.post(baseurl + "auth/access-tokens", payload_token);
    return res.data.access_token;
  },

  async GetDataInvoices(auth_token, contact_id) {
    let payload_invoice = {
      "with": "positions,contact_name",
      "sort": "-number",
      "limit": "200",
      "filter": { "contact_id": contact_id }
    }
    let res = await axios.get(baseurl + "receivables/invoices",
      {
        params: payload_invoice,
        headers: { 'Authorization': auth_token }
      }
    );
    return res.data;
  },

  async GetContacts(auth_token) {

    let res = await axios.get(baseurl + "contacts",
      {
        headers: { 'Authorization': auth_token }
      }
    );

    return res.data;
  },

  async GetPDFPath(auth_token, invoice_ID) {
    let res = await axios.get(baseurl + "receivables/invoices/" + invoice_ID + "/pdf",
      {
        headers: { 'Authorization': auth_token },
        responseType: 'blob'
      }
    );
    return res.data;
  },
  async GetPreview(auth_token, invoice_ID, page) {
    let res = await axios.get(baseurl + "receivables/invoices/" + invoice_ID + "/preview?page="+page+"&size=972",
      {
        headers: { 'Authorization': auth_token },
        responseType: 'arraybuffer'
      }
    );
    return res;
  },

  async GetContactsAll(auth_token) {
    let dataarray = []
    let datatemp = []
    let offset = 0
    let limit = 100 // Anzahl Resultate pro API Call (1-200)
    let payload_contacts = {
      "offset": offset,
      "limit": limit
    }

    let res = await axios.get(baseurl + "contacts",
      {
        params: payload_contacts,
        headers: { 'Authorization': auth_token }
      }
    );

    for (let index = 0; index < res.data.items.length; index++) {
      datatemp = res.data.items[index];
      dataarray.push({ id: datatemp.id, number: datatemp.number, name: datatemp.name, name_addition: datatemp.name_addition })
    }

    for (let index = 0; index < res.data.items.length; index++) {
      offset = offset + limit
      payload_contacts = { "offset": offset }
      let res = await axios.get(baseurl + "contacts",
        {
          params: payload_contacts,
          headers: { 'Authorization': auth_token }
        });

      for (let index = 0; index < res.data.items.length; index++) {
        datatemp = res.data.items[index];
        dataarray.push({ id: datatemp.id, number: datatemp.number, name: datatemp.name, name_addition: datatemp.name_addition })
      }


    }
    return dataarray;
  },
  async SearchInvoices(auth_token, payload) {
    let dataarray = []
    let datatemp = []
    let datatemp2 = []
    let pagination=[]
    let payload_invoice = {
      "with": "positions,contact_name",
    }
    let res = await axios.get(payload,
      {
        params: payload_invoice,
        headers: { 'Authorization': auth_token }
      }
    );
/*     for (let index = 0; index < res.data.items.length; index++) {
      pagination=res.data.pagination[0];
      datatemp = res.data.items[index];
      for (let index2 = 0; index2 < datatemp.positions.length; index2++) {
        datatemp2 = datatemp.positions[index2]
        if (datatemp2.catalog_type == "P" && datatemp2.name !="") {
          dataarray.push({ id: datatemp.id, number: datatemp.number, date: datatemp.date, name: datatemp2.name, contact_name: datatemp.contact_name })
        }

      }
    } */
    
    return res;
  },
}