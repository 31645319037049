import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bulma/css/bulma.css';
import axios from 'axios';
import SmartTable from 'vuejs-smart-table'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.use(SmartTable)
Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
